<template>
<v-container>
    <template class="text-center" v-if="!urlExist">
          <v-alert v-if="errorConsult"
            border="left"
            color="red"
            type="error"
            dismissible
            v-model="errorConsult"
            >
            {{errorConsultText}}
            
          </v-alert>

          <v-alert v-if="okConsult"
            border="left"
            color="green"
            type="success"
            v-model="okConsult"
            dismissible
            >
            {{okConsultText}}
            
          </v-alert>

          <v-container fluid class="text-center">
            <v-card  elevation="2"
            outlined class="pl-4 pr-4 pt-4 pb-4">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              
            >
          <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      ID
                    </th>
                    <th class="text-left">
                      Type
                    </th>
                    <th class="text-left">
                      Destination
                    </th>
                    <th class="text-left">
                      Description
                    </th>
                    <th class="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, k) in orgDBConnectors"
                    :key="item.id"
                  >   
                    <td class="text-left"><small>{{ item.id_organization }}</small></td>
                    <!--<td class="text-left"><small>connector.esmtcx.solutions/c/{{ item.id_organization }}</small></td>-->
                    <td class="text-left"><small>{{ item.product_connector }}</small></td>
                    <td class="text-left"><small>{{ item.url_client_service }}</small></td>
                    <td class="text-left"><small>{{ item.description }}</small></td>
                    <td>
                    
                      <v-btn
                      elevation="2"
                      
                      small
                      @click="copyURL('https://connector.esmtcx.solutions/c/'+item.id_organization)"
                      >
                      <v-icon
                        color="green"
                        >
                        mdi-content-copy
                      </v-icon>
                      </v-btn>

                      <v-btn
                      elevation="2"
                      small
                      @click="testURL('https://connector.esmtcx.solutions/c/'+item.id_organization)"
                      >
                      <v-icon
                        color="purple"
                        >
                        mdi-link
                      </v-icon>
                      </v-btn>
                      
                      <v-btn color="primary"
                      elevation="2"
                      small
                      v-if="item.product_connector=='PowerBI' && item.url_client_service == 'Data from ESMT connector'"
                      @click="consultURLExist(item.id_organization)"
                      >
                      <v-icon
                        color="white"
                        >
                        mdi-pencil
                      </v-icon>
                      </v-btn>
                      <v-btn 
                      elevation="0"
                       small
                      @click="deleteConnector(item.id_organization, k)"
                      >
                        <v-icon
                        color="red"
                        >
                          mdi-delete-circle
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
              <!--<v-btn  :disabled="urlClient==''"
              
                color="success"
                class="mr-4"
                @click="consultURLExist()"
              >
                Consultar URL
              </v-btn>-->
              
            </v-form>
            </v-card>
            </v-container>
    </template>
    
    <template class="text-center" v-else>

      <v-alert v-if="errorConsult"
            border="left"
            color="red"
            type="error"
            dismissible
            v-model="errorConsult"
            >
            {{errorConsultText}}
            
          </v-alert>

          <v-alert v-if="okConsult"
            border="left"
            color="green"
            type="success"
            v-model="okConsult"
            dismissible
            >
            {{okConsultText}}
            
          </v-alert>

          <v-container class="text-center" style="max-width:800px;">
            <v-card  elevation="2"
            outlined class="pl-2 pr-2 pt-2 pb-2 bgColorESMT">

            <v-card-text>
              <h4 style="color:#FFF;">ID del conector: {{urlClient}}<!-- | Cantidad de ejecuciones: {{connectorConnections}} --></h4>
              <v-btn
                      elevation="2"
                      x-small
                      class="ml-1"
                      @click="testURL('https://connector.esmtcx.solutions/c/'+urlClient)"
                      >
                      Test connector
                      </v-btn>

              <v-btn
                      elevation="2"
                      x-small
                      class="ml-1"
                      @click="copyURL('https://connector.esmtcx.solutions/c/'+urlClient)"
                      >
                      Copy connector
                      </v-btn>

              <v-btn
                      elevation="2"
                      x-small
                      class="ml-1"
                      color="red"
                      @click="redoToAllConnectors()"
                      >
                      Back to connectors
                      </v-btn>

            </v-card-text>
            </v-card>
          </v-container>

          <v-container class="text-center">
            <v-row>
              <v-col cols="8">
                <v-select
                  :items="metricTemplates"
                  @change="selectNewMetric"
                  name="id"
                  item-value="key"
                  value="id"
                  class="mb-0 pb-0"
                  dense
                  solo
                  label="Templates de métricas"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name}}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-btn color="success" block :disabled="createNewMetric=='' || createNewMetric==null" @click="createNewMetricFinish"
                class="mr-4">Activar Métrica</v-btn>
              </v-col>
            </v-row>
            
          </v-container>

          <v-container v-if="orgMetricsTempNum>0">
            <v-row v-for="(metrica, key) in orgMetricsTemp" :key="metrica.tempID">
              <!-- Métricas de Flujo -->
              <v-col cols="12" v-if="metrica.type=='auto'">
              <v-container>
                <v-card  elevation="2"
                outlined class="pl-4 pr-4 pt-4 pb-4">
                <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-key
                    </v-icon>
                    <span class="text-h6 font-weight-light">{{metrica.name}}</span>

                    <v-btn 
                      elevation="0"
                      small
                      @click="deleteMetric(key, metrica.id)"
                      style="position:absolute; clear:both; float:right; margin:0; right:10px; top:20px;"
                      >
                        <v-icon
                        color="red"
                        >
                          mdi-delete-circle
                        </v-icon>
                      </v-btn>
                </v-card-title>
                
                
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" width="20%">
                            Name
                          </th>
                          <th class="text-left">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;">Descripción de la métrica</td>
                          <td><!--@click="updateStatusMetrics0Group(key)"-->
                            <v-text-field
                              v-model="metrica.description"
                              label="Describe tu métrica aquí"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Método de Agrupación</td>
                          <td><!--@click="updateStatusMetrics0Group(key)"-->
                            <v-chip
                                class="ma-1"
                                text-color="white"
                                :color="!orgMetricsTemp[key].data1[key3][Object.keys(orgMetricsTemp[key].data1[key3])] ? 'grey':'success'"
                                small
                                v-for="(group, key3) in metrica.data1" :key="group.id"
                                @click="updateStatusMetricsGroupAuto(key, key3, metrica.type)"
                                >
                                {{Object.keys(group)}}
                            </v-chip>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Métricas</td>
                          <td><!--@click="updateStatusMetrics0(key)"-->
                             <v-chip
                                class="ma-1"
                                text-color="white"
                                :color="!orgMetricsTemp[key].data0[key2][Object.keys(orgMetricsTemp[key].data0[key2])] ? 'grey':'success'"
                                small
                                v-for="(metric, key2) in orgMetricsTemp[key].data0" :key="metric.id"
                                @click="updateStatusMetricsAuto(key, key2, metrica.type)"
                                >
                                {{Object.keys(metric)}}
                            </v-chip>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Número de días</td>
                          <td><v-select
                            :items="orgMetricsTemp[key].data2"
                            v-model="orgMetricsTemp[key].range"
                            label="Seleccionar"
                            dense
                            class="mt-6"
                          ></v-select></td>
                        </tr>
                        <tr v-if="metrica.type=='auto' && orgMetricsTemp[key].range == 'Personalizado'">
                          <td style="font-size:12px;">Rango personalizado</td>
                          <td>
                          <v-date-picker class="position-absolute"
                              v-model="orgMetricsTemp[key].customRange"
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              no-title
                              range
                            ></v-date-picker>
                         
                            <v-text-field
                              v-model="orgMetricsTemp[key].customRange"
                              label="Rango Seleccionado"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                        </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  
              <v-container class="mb-0 pb-0 text-center">
               <v-textarea
                  filled
                  name="input-metric"
                  label="JSON request"
                  readonly
                  v-model="metrica.text"
                ></v-textarea>
              </v-container>
                <v-container class="mt-0 pt-0 text-center">
                    <v-btn @click="updateDynamicMetricsInDB(key)" color="success" style="display:inline-block;">
                        Actualizar Métrica
                    </v-btn>
                </v-container>
                </v-card> 
              </v-container>
            </v-col>

            <!-- Métricas de Flujo -->
            <v-col cols="12" v-else-if="metrica.type=='auto2'">
              <v-container>
                <v-card  elevation="2"
                outlined class="pl-4 pr-4 pt-4 pb-4">
                <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-key
                    </v-icon>
                    <span class="text-h6 font-weight-light">{{metrica.name}}</span>
                    <v-btn 
                      elevation="0"
                      small
                      @click="deleteMetric(key, metrica.id)"
                      style="position:absolute; clear:both; float:right; margin:0; right:10px; top:20px;"
                      >
                        <v-icon
                        color="red"
                        >
                          mdi-delete-circle
                        </v-icon>
                      </v-btn>
                </v-card-title>
                
                
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" width="20%">
                            Name
                          </th>
                          <th class="text-left">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;">Descripción de la métrica</td>
                          <td><!--@click="updateStatusMetrics0Group(key)"-->
                            <v-text-field
                              v-model="metrica.description"
                              
                              label="Describe tu métrica aquí"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Método de Agrupación</td>
                          <td><!--@click="updateStatusMetrics0Group(key)"-->
                            <v-chip
                                class="ma-1"
                                text-color="white"
                                :color="!orgMetricsTemp[key].data1[key3][Object.keys(orgMetricsTemp[key].data1[key3])] ? 'grey':'success'"
                                small
                                v-for="(group, key3) in metrica.data1" :key="group.id"
                                @click="updateStatusMetricsGroupAuto(key, key3, metrica.type)"
                                >
                                {{Object.keys(group)}}
                            </v-chip>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Métricas</td>
                          <td><!--@click="updateStatusMetrics0(key)"-->
                             <v-chip
                                class="ma-1"
                                text-color="white"
                                :color="!orgMetricsTemp[key].data0[key2][Object.keys(orgMetricsTemp[key].data0[key2])] ? 'grey':'success'"
                                small
                                v-for="(metric, key2) in orgMetricsTemp[key].data0" :key="metric.id"
                                @click="updateStatusMetricsAuto(key, key2, metrica.type)"
                                >
                                {{Object.keys(metric)}}
                            </v-chip>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Filtro</td>
                          <td>
                            <v-select
                              :items="orgMetricsTemp[key].data3"
                              v-model="orgMetricsTemp[key].filter"
                              label="Seleccionar"
                              dense
                              class="mt-6"
                            ></v-select>
                            <v-autocomplete
                              v-model="metrica.filterValue"
                              :items="orgUsers"
                              chips
                              small
                              small-chips
                              label="IDs de usuarios"
                              full-width
                              hide-details
                              hide-no-data
                              hide-selected
                              item-value="id"
                              item-text="name"
                              multiple
                              single-line
                              @change="writeJSONMetricsAuto(key, metrica.type, 'json')"
                            >
                            <!--<template slot="selection" slot-scope="data">
                              {{ data.item.name}}
                            </template>-->
                            </v-autocomplete>
                            <!--<v-text-field
                              v-model="metrica.filterValue"
                              v-if="orgMetricsTemp[key].filter!=null && orgMetricsTemp[key].filter!=''"
                              
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              label="IDs de usuarios separados por commas"
                            ></v-text-field>-->
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Número de días</td>
                          <td><v-select
                            :items="orgMetricsTemp[key].data2"
                            v-model="orgMetricsTemp[key].range"
                            label="Seleccionar"
                            dense
                            class="mt-6"
                          ></v-select></td>
                        </tr>
                        <tr v-if="metrica.type=='auto2' && orgMetricsTemp[key].range == 'Personalizado'">
                          <td style="font-size:12px;">Rango personalizado</td>
                          <td>
                          <v-date-picker class="position-absolute"
                              v-model="orgMetricsTemp[key].customRange"
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              no-title
                              range
                            ></v-date-picker>
                         
                            <v-text-field
                              v-model="orgMetricsTemp[key].customRange"
                              label="Rango Seleccionado"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                        </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  
                
              <v-container class="mb-0 pb-0 text-center">
               <v-textarea
                  filled
                  name="input-metric"
                  label="JSON request"
                  readonly
                  v-model="metrica.text"
                ></v-textarea>
              </v-container>
                <v-container class="mt-0 pt-0 text-center">
                    <v-btn @click="updateDynamicMetricsInDB(key)" color="success" style="display:inline-block;">
                        Actualizar Métrica
                    </v-btn>
                </v-container>
                </v-card> 
              </v-container>
            </v-col>

            <v-col cols="12" v-else-if="metrica.type=='auto3'">
              <v-container>
                <v-card  elevation="2"
                outlined class="pl-4 pr-4 pt-4 pb-4">
                <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-key
                    </v-icon>
                    <span class="text-h6 font-weight-light">{{metrica.name}}</span>
                    <v-btn 
                      elevation="0"
                      small
                      @click="deleteMetric(key, metrica.id)"
                      style="position:absolute; clear:both; float:right; margin:0; right:10px; top:20px;"
                      >
                        <v-icon
                        color="red"
                        >
                          mdi-delete-circle
                        </v-icon>
                      </v-btn>
                </v-card-title>
                
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" width="20%">
                            Name
                          </th>
                          <th class="text-left">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;">Descripción de la métrica</td>
                          <td><!--@click="updateStatusMetrics0Group(key)"-->
                            <v-text-field
                              v-model="metrica.description"
                              
                              label="Describe tu métrica aquí"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Metodo de ordenamiento</td>
                          <td><!--@click="updateStatusMetrics0Group(key)"-->
                            <v-select
                              :items="orgMetricsTemp[key].data4"
                              v-model="orgMetricsTemp[key].metric_order"
                              label="Seleccionar"
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              dense
                              class="mt-6"
                            ></v-select>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Parámetro de consulta</td>
                          <td><!--@click="updateStatusMetrics0(key)"-->
                            <v-select
                              :items="orgMetricsTemp[key].data5"
                              v-model="orgMetricsTemp[key].metric_orderBy"
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              label="Seleccionar"
                              dense
                              class="mt-6"
                            ></v-select>
                          </td>
                        </tr>
                        <!--<tr>
                          <td style="font-size:12px;">Filtro</td>
                          <td>
                            <v-select
                              :items="orgMetricsTemp[key].data3"
                              v-model="orgMetricsTemp[key].filter"
                              label="Seleccionar"
                              dense
                              class="mt-6"
                            ></v-select>
                            <v-text-field
                              v-model="metrica.filterValue"
                              v-if="orgMetricsTemp[key].filter!=null && orgMetricsTemp[key].filter!=''"
                              
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              label="IDs de usuarios separados por commas"
                            ></v-text-field>
                          </td>
                        </tr>-->
                        <tr>
                          <td style="font-size:12px;">Paginación</td>
                          <td>
                            <v-text-field
                              v-model="metrica.paging"
                              type="number"
                              label="Paginación de resultados"
                              disabled
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Número de días</td>
                          <td><v-select
                            :items="orgMetricsTemp[key].data2"
                            v-model="orgMetricsTemp[key].range"
                            label="Seleccionar"
                            dense
                            class="mt-6"
                          ></v-select></td>
                        </tr>
                        <tr v-if="metrica.type=='auto3' && orgMetricsTemp[key].range == 'Personalizado'">
                          <td style="font-size:12px;">Rango personalizado</td>
                          <td>
                          <v-date-picker class="position-absolute"
                              v-model="orgMetricsTemp[key].customRange"
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              no-title
                              range
                            ></v-date-picker>
                         
                            <v-text-field
                              v-model="orgMetricsTemp[key].customRange"
                              label="Rango Seleccionado"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                        </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  
                
              <v-container class="mb-0 pb-0 text-center">
               <v-textarea
                  filled
                  name="input-metric"
                  label="JSON request"
                  readonly
                  v-model="metrica.text"
                ></v-textarea>
              </v-container>
                <v-container class="mt-0 pt-0 text-center">
                    <v-btn @click="updateDynamicMetricsInDB(key)" color="success" style="display:inline-block;">
                        Actualizar Métrica
                    </v-btn>
                </v-container>
                </v-card> 
              </v-container>
            </v-col>

            <v-col cols="12" v-else-if="metrica.type=='auto4'">
              <v-container>
                <v-card  elevation="2"
                outlined class="pl-4 pr-4 pt-4 pb-4">
                <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-key
                    </v-icon>
                    <span class="text-h6 font-weight-light">{{metrica.name}}</span>
                    <v-btn 
                      elevation="0"
                      small
                      @click="deleteMetric(key, metrica.id)"
                      style="position:absolute; clear:both; float:right; margin:0; right:10px; top:20px;"
                      >
                        <v-icon
                        color="red"
                        >
                          mdi-delete-circle
                        </v-icon>
                      </v-btn>
                </v-card-title>
                
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" width="20%">
                            Name
                          </th>
                          <th class="text-left">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;">Descripción de la métrica</td>
                          <td><!--@click="updateStatusMetrics0Group(key)"-->
                            <v-text-field
                              v-model="metrica.description"
                              
                              label="Describe tu métrica aquí"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Metodo de ordenamiento</td>
                          <td>
                            <v-select
                              :items="orgMetricsTemp[key].data4"
                              v-model="orgMetricsTemp[key].metric_order"
                              label="Seleccionar"
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              dense
                              class="mt-6"
                            ></v-select>
                          </td>
                        </tr>
                        <!--<tr>
                          <td style="font-size:12px;">Parámetro de consulta</td>
                          <td>
                            <v-select
                              :items="orgMetricsTemp[key].data5"
                              v-model="orgMetricsTemp[key].metric_orderBy"
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              label="Seleccionar"
                              dense
                              class="mt-6"
                            ></v-select>
                          </td>
                        </tr>-->
                        <tr>
                          <td style="font-size:12px;">Paginación</td>
                          <td>
                            <v-text-field
                              v-model="metrica.paging"
                              type="number"
                              label="Paginación de resultados"
                              disabled
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-size:12px;">Número de días</td>
                          <td><v-select
                            :items="orgMetricsTemp[key].data2"
                            v-model="orgMetricsTemp[key].range"
                            label="Seleccionar"
                            dense
                            class="mt-6"
                            @change="writeJSONMetricsAuto(key, metrica.type)"
                          ></v-select></td>
                        </tr>
                        <tr v-if="metrica.type=='auto4' && orgMetricsTemp[key].range == 'Personalizado'">
                          <td style="font-size:12px;">Rango personalizado</td>
                          <td>
                          <v-date-picker class="position-absolute"
                              v-model="orgMetricsTemp[key].customRange"
                              @change="writeJSONMetricsAuto(key, metrica.type)"
                              no-title
                              range
                            ></v-date-picker>
                         
                            <v-text-field
                              v-model="orgMetricsTemp[key].customRange"
                              label="Rango Seleccionado"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                        </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  
                
              <v-container class="mb-0 pb-0 text-center">
               <v-textarea
                  filled
                  name="input-metric"
                  label="JSON request"
                  readonly
                  v-model="metrica.text"
                ></v-textarea>
              </v-container>
                <v-container class="mt-0 pt-0 text-center">
                    <v-btn @click="updateDynamicMetricsInDB(key)" color="success" style="display:inline-block;">
                        Actualizar Métrica
                    </v-btn>
                </v-container>
                </v-card> 
              </v-container>
            </v-col>

            </v-row>
          </v-container>

          <!--<v-row>
            <v-col cols="12">
              <v-container>
                <v-card  elevation="2"
                outlined class="pl-4 pr-4 pt-4 pb-4">
                <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-key
                    </v-icon>
                    <span class="text-h6 font-weight-light">Métrica Personalizada</span>
                </v-card-title>
                <v-container class="mb-0 pb-0 text-center">
                <v-text-field
                  label="Filled"
                  filled
                  v-model="Metrics2Description"
                ></v-text-field>
                </v-container>
                <v-container class="mb-0 pb-0 text-center">
                <v-textarea
                    filled
                    name="input-metric2"
                    label="JSON request"
                    auto-grow
                    v-model="Metrics2Result"
                  ></v-textarea>
                </v-container>
                <v-container class="mt-0 pt-0 text-center">
                    <v-btn @click="updateMetricsInDB('Metrics2')" color="success" style="display:inline-block;">
                        Actualizar Métrica
                    </v-btn>
                </v-container>
                </v-card>
                
                </v-container>
              </v-col>
            </v-row>-->
    </template>
  </v-container>
  <!--<v-container v-else>
      <h2 class="text-center">App Autenticada</h2>
      <p>Ya puedes usar la App</p>
  </v-container>-->
</template>

<script>
import axios from 'axios'
export default {
    
    name:'PowerBIConfig',
    props:{
      isAuthPropComp: {type: Boolean, default:false},
      tokenPropComp: {type: String, default:null},
      orgIdPropComp: {type: String, default:null}
    },
    data() {
        return {
          customDates: ['2019-09-10', '2019-09-20'],
          customDatesRangeText:'',
          defaultCustomRange:null,
          testing:'',
            connectorConnections:0,
            connectorConnectionsOk:0,
            connectorConnectionsError:0,
            createNewMetric:null,
            errorConsult:false,
            errorConsultText:'',
            okConsult:false,
            okConsultText:'',
            clientId: '',
            orgDBID: this.orgIdPropComp,
            orgDBConnectors:null,
            organization_id: '',
            //secretClient: '',
            urlExist:false,
            urlClient:'',
            clientSecret:'',
            tokenGC: '',
            token: '',
            jsonOauthToken: {},
            dataUrlOrg:null,
            isChanged:true,
            orgMetricsTemp:[],
            orgMetricsTempNum:0,
            orgUsers:[],
            message:{
                from: "",
                pre: "",
                to: "",
                body: ""
            },
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            valid:false,
            typeSelected:'',
            theApp:{
                name:'',
                description: '',
                state: ''
            },
            tokenGenerate:false,
            initGenerateToken:false,
            autoTokenGenerate: false,
            urlCONNECTOR: this.$urlCONNECTOR,
            urlAPP: this.$urlAPP,

            metricTemplates:null
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
    methods:{

      redoToAllConnectors(){
        this.urlExist=false
        this.urlClient=''
      },
      
      async deleteMetric(a=null, b=null){

        let key = a
        let id = b

        console.log(key+', '+id)

        this.orgMetricsTemp.splice(key, 1)

        if(id==null || id==''){
          return false
        }

        let params = new FormData();

        params.append('id', id)
        params.append('urlClient', this.urlClient)

              await axios.post(this.urlCONNECTOR+'deleteOrgMetric.php', params, {
                    headers:{

                    }
                    }).then(response => {
                        console.log(response)

                        console.log(response.data)
                        console.log(response.data.code)
                        if(response.data.code == '01'){

                            this.okConsult = true
                            this.okConsultText = response.data.data

                        }else{
                            this.errorConsult = true
                            this.errorConsultText = response.data.error
                        }

                    }).catch(error => {
                        this.errorConsult = true
                        this.errorConsultText = error
                        console.error(error)

                  //this.auth = false

                })

      },

      async deleteConnector(a, b) {
        console.log(a)
        console.log(b)

        let params = new FormData();
        //params.append('id', a)
        params.append('urlClient', a)

        await axios.post(this.urlCONNECTOR+'deleteOrgConnector.php', params, {
                    headers:{

                    }
                    }).then(response => {
                        console.log(response)

                        console.log(response.data)
                        console.log(response.data.code)
                        if(response.data.code == '01'){

                            this.okConsult = true
                            this.okConsultText = response.data.data

                            this.$delete(this.orgDBConnectors, b)

                        }else{
                            this.errorConsult = true
                            this.errorConsultText = response.data.error
                        }

                    }).catch(error => {
                        this.errorConsult = true
                        this.errorConsultText = error
                        console.error(error)

                  //this.auth = false

                })

      },

      async copyURL(mytext) {
        try {
          await navigator.clipboard.writeText(mytext);
          this.okConsult = true
          this.okConsultText = 'Enlace Copiado exitosamente'
          console.log('Url copiada')
        } catch($e) {
          this.errorConsult = true
          this.errorConsultText = 'No se ha copiado el link.'
          console.log('Error al copiar url. Intentalo más tarde.')
        }
      },

      async testURL(mytext) {
        window.open(mytext, '_blank');
      },

        selectNewMetric(e){
          let newArrayTemplateC = Object.assign({}, e)
          this.createNewMetric = newArrayTemplateC
        },

        createNewMetricFinish(){
          //
          
          let actualMetricsN = this.orgMetricsTempNum
          
          console.log('//////////// Creando nueva métrica //////////////////')
          console.log(actualMetricsN)

          let newArrayTemplateC = Object.assign({}, this.createNewMetric)

          if(newArrayTemplateC.type=='auto'){

            if(actualMetricsN<=0){
              
              console.log('Estoy en el punto 0')
              this.orgMetricsTemp[0] = newArrayTemplateC
              let data0New = Object.assign({}, newArrayTemplateC.data0)
              let data1New = Object.assign({}, newArrayTemplateC.data1)
              
              let data0newJ= JSON.parse(JSON.stringify(data0New))
              let data1newJ= JSON.parse(JSON.stringify(data1New))
              
              this.$set(this.orgMetricsTemp[0], 'data0', data0newJ)
              this.$set(this.orgMetricsTemp[0], 'data1', data1newJ)
              
              this.$set(this.orgMetricsTemp[0], 'data0', data0New)
              this.$set(this.orgMetricsTemp[0], 'data1', data1New)
              this.$set(this.orgMetricsTemp[0], 'id', '')
              this.$set(this.orgMetricsTemp[0], 'tempID', 0)
              this.$set(this.orgMetricsTemp[0], 'result', null)
              this.$set(this.orgMetricsTemp[0], 'text', '')
              this.$set(this.orgMetricsTemp[0], 'range', 'Hoy')
              this.$set(this.orgMetricsTemp[0], 'metric_type', newArrayTemplateC.type)
            }else{
              let data0New = Object.assign({}, newArrayTemplateC.data0)
              let data1New = Object.assign({}, newArrayTemplateC.data1)

              let data0newJ= JSON.parse(JSON.stringify(data0New))
              let data1newJ= JSON.parse(JSON.stringify(data1New))

              this.$set(this.orgMetricsTemp, actualMetricsN, newArrayTemplateC)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'data0', data0newJ)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'data1', data1newJ)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'id', '')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'tempID', (actualMetricsN))
              this.$set(this.orgMetricsTemp[actualMetricsN], 'result', null)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'text', '')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'range', 'Hoy')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'metric_type', newArrayTemplateC.type)
            }
          }else if(newArrayTemplateC.type=='auto2'){
            

            if(actualMetricsN<=0){
              
              console.log('Estoy en el punto 0')
              this.orgMetricsTemp[0] = newArrayTemplateC

              let filterJSON = this.orgMetricsTemp[0].data3
              let filter = filterJSON[0]

              console.log(filter)

              let data0New = Object.assign({}, newArrayTemplateC.data0)
              let data1New = Object.assign({}, newArrayTemplateC.data1)
              let data0newJ= JSON.parse(JSON.stringify(data0New))
              let data1newJ= JSON.parse(JSON.stringify(data1New))

              let dataorgUsersNew = Object.assign({}, this.orgUsers)
              let dataorgUsersNewJ= JSON.parse(JSON.stringify(dataorgUsersNew))

              this.$set(this.orgMetricsTemp[0], 'data0', data0newJ)
              this.$set(this.orgMetricsTemp[0], 'data1', data1newJ)

              /*this.$set(this.orgMetricsTemp[0], 'data0', data0New)
              this.$set(this.orgMetricsTemp[0], 'data1', data1New)*/
              this.$set(this.orgMetricsTemp[0], 'id', '')
              this.$set(this.orgMetricsTemp[0], 'tempID', 0)
              this.$set(this.orgMetricsTemp[0], 'result', null)
              this.$set(this.orgMetricsTemp[0], 'text', '')
              this.$set(this.orgMetricsTemp[0], 'range', 'Hoy')
              this.$set(this.orgMetricsTemp[0], 'metric_type', newArrayTemplateC.type)
              this.$set(this.orgMetricsTemp[0], 'filter', filter)
              this.$set(this.orgMetricsTemp[0], 'filterValue', '')

              this.$set(this.orgMetricsTemp[0], 'dataUserID', dataorgUsersNewJ)
            }else{

              this.$set(this.orgMetricsTemp, actualMetricsN, newArrayTemplateC)
              let filterJSON = this.orgMetricsTemp[actualMetricsN].data3
              let filter = filterJSON[0]

              console.log(filter)

              let data0New = Object.assign({}, newArrayTemplateC.data0)
              let data1New = Object.assign({}, newArrayTemplateC.data1)
              let data0newJ= JSON.parse(JSON.stringify(data0New))
              let data1newJ= JSON.parse(JSON.stringify(data1New))

              let dataorgUsersNew = Object.assign({}, this.orgUsers)
              let dataorgUsersNewJ= JSON.parse(JSON.stringify(dataorgUsersNew))

              this.$set(this.orgMetricsTemp[actualMetricsN], 'data0', data0newJ)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'data1', data1newJ)

              this.$set(this.orgMetricsTemp[actualMetricsN], 'id', '')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'tempID', (actualMetricsN))
              this.$set(this.orgMetricsTemp[actualMetricsN], 'result', null)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'text', '')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'range', 'Hoy')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'metric_type', newArrayTemplateC.type)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'filter', filter)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'filterValue', '')

              this.$set(this.orgMetricsTemp[actualMetricsN], 'dataUserID', dataorgUsersNewJ)
            }

            
          }else if(newArrayTemplateC.type=='auto3' || newArrayTemplateC.type=='auto4'){
            

            if(actualMetricsN<=0){
              
              console.log('Estoy en el punto 0')
              this.orgMetricsTemp[0] = newArrayTemplateC
/*
              let filterJSON = this.orgMetricsTemp[0].data3
              let filter = filterJSON[0]

              console.log(filter)*/

              let filter = []

              let data4New = Object.assign({}, newArrayTemplateC.data4)
              let data5New = Object.assign({}, newArrayTemplateC.data5)
              let data4newJ= JSON.parse(JSON.stringify(data4New))
              let data5newJ= JSON.parse(JSON.stringify(data5New))
/*
              this.$set(this.orgMetricsTemp[0], 'data4', data4newJ)
              this.$set(this.orgMetricsTemp[0], 'data5', data5newJ)*/

              /*this.$set(this.orgMetricsTemp[0], 'data0', data0New)
              this.$set(this.orgMetricsTemp[0], 'data1', data1New)*/
              this.$set(this.orgMetricsTemp[0], 'id', '')
              this.$set(this.orgMetricsTemp[0], 'tempID', 0)
              this.$set(this.orgMetricsTemp[0], 'result', null)
              this.$set(this.orgMetricsTemp[0], 'text', '')
              this.$set(this.orgMetricsTemp[0], 'range', 'Hoy')
              this.$set(this.orgMetricsTemp[0], 'metric_type', newArrayTemplateC.type)
              this.$set(this.orgMetricsTemp[0], 'filter', filter)
              this.$set(this.orgMetricsTemp[0], 'filterValue', '')
              this.$set(this.orgMetricsTemp[0], 'paging', 100)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'metric_order', data4newJ)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'metric_orderBy', data5newJ)
            }else{

              this.$set(this.orgMetricsTemp, actualMetricsN, newArrayTemplateC)
              /*let filterJSON = this.orgMetricsTemp[actualMetricsN].data3
              let filter = filterJSON[0]

              console.log(filter)*/

              let filter = []

              /*let data4New = Object.assign({}, newArrayTemplateC.data4)
              let data5New = Object.assign({}, newArrayTemplateC.data5)
              let data4newJ= JSON.parse(JSON.stringify(data4New))
              let data5newJ= JSON.parse(JSON.stringify(data5New))

              this.$set(this.orgMetricsTemp[actualMetricsN], 'data4', data4newJ)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'data5', data5newJ)*/

              this.$set(this.orgMetricsTemp[actualMetricsN], 'id', '')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'tempID', (actualMetricsN))
              this.$set(this.orgMetricsTemp[actualMetricsN], 'result', null)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'text', '')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'range', 'Hoy')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'metric_type', newArrayTemplateC.type)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'filter', filter)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'filterValue', '')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'paging', 100)
              this.$set(this.orgMetricsTemp[actualMetricsN], 'metric_order', '')
              this.$set(this.orgMetricsTemp[actualMetricsN], 'metric_orderBy', '')
            }

            
          }

          actualMetricsN++
          this.orgMetricsTempNum = actualMetricsN
          
          this.createNewMetric  =  null

          console.log('//////////// Creando nueva métrica //////////////////')
          console.log(this.orgMetricsTempNum)
        },

        goToHome(){
            this.$router.push({ name: 'Welcome' })
        },

        updateMetricsInDB(m){
          let params = new FormData();

          console.log('Init update metric')

          if(this[`${m}Result`]==null){
            this.errorConsult = true
            this.errorConsultText = 'Debes tener al menos un método de agrupación y métrica seleccionado.'
            console.log('Error init update metric')
            return false
          }
          
          if(this[`${m}DaysRangeSelect`]<=0){
            this.errorConsult = true
            this.errorConsultText = 'Debes agregar un número de días para la recolección de métricas'
            console.log('Error init update metric')
            return false
          }

          let consult = this[`${m}Result`]
          let API = this[`${m}API`]
          let metric_name = this[`${m}Name`]
          let metric_historic_days	 = this[`${m}DaysRangeSelect`]
          let metric_description	 = this[`${m}Description`]

          if(metric_name != 'Custom Metric'){
              params.append('urlClient', this.urlClient)
              params.append('consult', consult)
              params.append('metric_name', metric_name)
              params.append('api', API)
              params.append('metric_historic_days', metric_historic_days)
          }else{
              params.append('urlClient', this.urlClient)
              params.append('consult', consult)
              params.append('metric_name', metric_name)
              params.append('metric_description', metric_description)
          }

            console.log(consult+API)

              axios.post(this.urlCONNECTOR+'updateOrgMetrics.php', params, {
                    headers:{

                    }
                    }).then(response => {
                        console.log(response)

                        console.log(response.data)
                        console.log(response.data.code)
                        if(response.data.code == '01'){

                            this.okConsult = true
                            this.okConsultText = response.data.data

                        }else{
                            this.errorConsult = true
                            this.errorConsultText = response.data.error
                        }

                    }).catch(error => {
                        this.errorConsult = true
                        this.errorConsultText = error
                        console.error(error)

                  //this.auth = false

                })
        },

        /////////////Metricas dinámicas

        updateDynamicMetricsInDB(m){
          let params = new FormData();

          console.log('Init update metric')

          if(this.orgMetricsTemp[m].result==null){
            this.errorConsult = true
            this.errorConsultText = 'Debes tener al menos un método de agrupación y métrica seleccionado.'
            console.log('Error init update metric')
            return false
          }
          
          if(this.orgMetricsTemp[m].range=='' || (this.orgMetricsTemp[m].range!='' && this.orgMetricsTemp[m].range!=null && this.orgMetricsTemp[m].range<=0)){
            this.errorConsult = true
            this.errorConsultText = 'Debes agregar un número de días para la recolección de métricas'
            console.log('Error init update metric')
            return false
          }

          if(this.orgMetricsTemp[m].type=='auto2' && (this.orgMetricsTemp[m].filter=='' || (this.orgMetricsTemp[m].filterValue=='' || ( Array.isArray(this.orgMetricsTemp[m].filterValue) && this.orgMetricsTemp[m].filterValue.length<=0 )) )){
            this.errorConsult = true
            this.errorConsultText = 'Los  campos Filtro no deben estar vacios'
            console.log('Error init update metric filtes')
            return false
          }

          let id = this.orgMetricsTemp[m].id
          let consult = this.orgMetricsTemp[m].result
          let API = this.orgMetricsTemp[m].api
          let metric_name = this.orgMetricsTemp[m].name
          let metric_historic_days	 = this.orgMetricsTemp[m].range
          let metric_description	 = this.orgMetricsTemp[m].description
          let metric_type	 = this.orgMetricsTemp[m].metric_type
          let filters	 = this.orgMetricsTemp[m].filter
          let filter_values	 = this.orgMetricsTemp[m].filterValue
          
          let paging	 = this.orgMetricsTemp[m].paging
          let order	 = this.orgMetricsTemp[m].metric_order
          let orderBy	 = this.orgMetricsTemp[m].metric_orderBy

          params.append('id', id)

          if(metric_type != 'custom'){
              params.append('urlClient', this.urlClient)
              params.append('consult', consult)
              params.append('metric_name', metric_name)
              params.append('api', API)
              params.append('metric_description', metric_description)
              params.append('metric_type', metric_type)
              params.append('metric_historic_days', metric_historic_days)
              
              if(metric_type=='auto2'){
                
                if(Array.isArray(filter_values)){
                  filter_values.toString()
                }

                params.append('filters', filters)
                params.append('filter_values', filter_values)
              }

              if(metric_type=='auto3'){
                params.append('paging', paging)
                params.append('metric_order', order)
                params.append('metric_orderBy', orderBy)
              }

              if(metric_type=='auto4'){
                params.append('paging', paging)
                params.append('metric_order', order)
              }
          }else{
              params.append('urlClient', this.urlClient)
              params.append('consult', consult)
              params.append('metric_name', metric_name)
              params.append('metric_description', metric_description)
              params.append('metric_type', metric_type)
          }

            

            console.log(consult+API)


              axios.post(this.urlCONNECTOR+'updateOrgMetrics.php', params, {
                    headers:{

                    }
                    }).then(response => {
                        console.log(response)

                        console.log(response.data)
                        console.log(response.data.code)
                        if(response.data.code == '01'){

                            this.okConsult = true
                            this.okConsultText = response.data.data

                        }else{
                            this.errorConsult = true
                            this.errorConsultText = response.data.error
                        }

                    }).catch(error => {
                        this.errorConsult = true
                        this.errorConsultText = error
                        console.error(error)

                  //this.auth = false

                })
        },

        

        updateStatusMetricsAuto(i, i2, t){
          

          console.log('Consultando métrica')
          
          let a= i
          let b= i2
          let c= t
          
          console.log(a)
          console.log(b)
          console.log(c)

          console.log(Object.keys(this.orgMetricsTemp[a].data0[b]))
          

          let theKey = Object.keys(this.orgMetricsTemp[a].data0[b])
          

          console.log(this.orgMetricsTemp[a].data0[b][theKey])
          //let isEmpty = true

          if(this.orgMetricsTemp[a].data0[b][theKey] == false){
            console.log('Cambiar a True')
            this.orgMetricsTemp[a].data0[b][theKey] = true

          }else{
            console.log('Cambiar a False')
            this.orgMetricsTemp[a].data0[b][theKey] = false
          }

          console.log('Hola '+c)

          this.writeJSONMetricsAuto(a, c)

        },

        updateStatusMetricsGroupAuto(i, i2, t){
          
          let a= i
          let b= i2
          let c= t
          
          console.log('Consultando agrupación')
          console.log(a)
          console.log(b)
          console.log(c)

          console.log(Object.keys(this.orgMetricsTemp[a].data1[b]))
          

          let theKey = Object.keys(this.orgMetricsTemp[a].data1[b])
          

          console.log(this.orgMetricsTemp[a].data1[b][theKey])
          //let isEmpty = true

          if(this.orgMetricsTemp[a].data1[b][theKey] == false){
            console.log('Cambiar a True')
            this.orgMetricsTemp[a].data1[b][theKey] = true

          }else{
            console.log('Cambiar a False')
            this.orgMetricsTemp[a].data1[b][theKey] = false
          }

          console.log('Hola '+c)

          this.writeJSONMetricsAuto(a, c)

        },

        writeJSONMetricsAuto(e, e2, e3=null){

          let a = e
          let b = e2
          let c = e3
          
          console.log('Preparando guardado')
          console.log('a= '+a)
          console.log('b= '+b)
          console.log('c= '+c)

          let arrayMetrics = []
          let metricsOK = 0
          
          if(b == 'auto' || b == 'auto2'){
            
            let i = 0

            let keys= Object.keys(this.orgMetricsTemp[a].data0).length
            console.log(keys)

            for(i=0; i<keys; i++){
              
              let theKey = Object.keys(this.orgMetricsTemp[a].data0[i])
              
              console.log('Probando ITEM '+theKey)

              if(this.orgMetricsTemp[a].data0[i][theKey]==true){
                console.log('Ingresando ITEM '+Object.keys(this.orgMetricsTemp[a].data0[i]))
                
                arrayMetrics[metricsOK] = ""+Object.keys(this.orgMetricsTemp[a].data0[i])+""

                metricsOK++
              }
            }
            //arrayMetrics.replace(/.$/, '')
            console.log(arrayMetrics)
          }

          let arrayMetricsFilters = []
          let metricsFiltersOK = 0

          if(b == 'auto2'){
            if(c==null){
              let u = 0 

              let keys3= this.orgMetricsTemp[a].filterValue.split(/(?:,| )+/)
              metricsFiltersOK = keys3.length
              console.log(metricsFiltersOK)

              if(metricsFiltersOK>0){
                for(u=0; u<metricsFiltersOK; u++){
                    arrayMetricsFilters[u]={"type": "dimension","dimension": this.orgMetricsTemp[a].filter,"operator": "matches","value": keys3[u]}
                }
              }
            }else{
              let u = 0 

              let keys3= this.orgMetricsTemp[a].filterValue
              metricsFiltersOK = keys3.length
              console.log('//>')
              console.log(metricsFiltersOK)

              if(metricsFiltersOK>0){
                for(u=0; u<metricsFiltersOK; u++){
                    arrayMetricsFilters[u]={"type": "dimension","dimension": this.orgMetricsTemp[a].filter,"operator": "matches","value": keys3[u]}
                }
              }
            }
            
          }

          let arrayMetricOrderBy = []
          let arrayMetricOrder = []

          if(b == 'auto3'){
            arrayMetricOrderBy = this.orgMetricsTemp[a].metric_orderBy
            arrayMetricOrder = this.orgMetricsTemp[a].metric_order
          }

          if(b == 'auto4'){
            arrayMetricOrder = this.orgMetricsTemp[a].metric_order
          }

          let interval = this.defaultCustomRange[0]+'T00:00:00.000Z/'+this.defaultCustomRange[1]+'T23:59:59.000Z'

          if(this.orgMetricsTemp[a].range=='Personalizado' && this.orgMetricsTemp[a].customRange[0]!='' && this.orgMetricsTemp[a].customRange[1]!=''){
            //this.orgMetricsTemp[a].customRange[0]!=
            interval = this.orgMetricsTemp[a].customRange[0]+'T00:00:00.000Z/'+this.orgMetricsTemp[a].customRange[1]+'T23:59:59.000Z'

          }

          ////////////////////////////
          let arrayMetricsGroup = []
          let metricsGroupOK = 0
          if(b == 'auto' || b == 'auto2'){
            let o = 0 

            let keys2= Object.keys(this.orgMetricsTemp[a].data1).length
            console.log(keys2)

            for(o=0; o<keys2; o++){

              let theKey = Object.keys(this.orgMetricsTemp[a].data1[o])

              console.log('Probando ITEM '+theKey)

              if(this.orgMetricsTemp[a].data1[o][theKey]==true){
                console.log('Probando ITEM '+Object.keys(this.orgMetricsTemp[a].data1[o]))
                
                arrayMetricsGroup[metricsGroupOK] = ""+Object.keys(this.orgMetricsTemp[a].data1[o])+""

                metricsGroupOK++
              }
            }
          }

          //arrayMetrics.replace(/.$/, '')
          //console.log(arrayMetricsGroup)
          console.log(arrayMetrics)
          console.log(arrayMetricsFilters)
          console.log(arrayMetricsGroup)
          console.log(arrayMetricOrderBy)
          console.log(arrayMetricOrder)

          if((arrayMetrics.length>0 && arrayMetricsGroup.length>0) || (arrayMetricOrder.length>0 || arrayMetricOrderBy.length>0)){
            let textJSON = {}

            if(b=='auto'){
              textJSON = {
                "interval": interval,
                "groupBy": arrayMetricsGroup,
                "metrics": arrayMetrics
              }
            }

            if(b=='auto2'){
              textJSON = {
                "interval": interval,
                "groupBy": arrayMetricsGroup,
                "filter": {"type": "or","predicates":arrayMetricsFilters},
                "metrics": arrayMetrics
              }
            }

            if(b=='auto3'){
              textJSON = {
                "interval": interval,
                "order": arrayMetricOrder,
                "orderBy": arrayMetricOrderBy,
                "paging": {
                  "pageSize": "100",
                  "pageNumber": 1
                }
              }
            }

            if(b=='auto4'){
              textJSON = {
                "interval": interval,
                "order": arrayMetricOrder,
                "paging": {
                  "pageSize": "100",
                  "pageNumber": 1
                }
              }
            }
            
            this.orgMetricsTemp[a].result = JSON.stringify(textJSON)
            this.orgMetricsTemp[a].text = this.orgMetricsTemp[a].result
            console.log(this.orgMetricsTemp[a].text)

          }else{
            this.orgMetricsTemp[a].result  = null
            this.orgMetricsTemp[a].text = ''
          }

          
        },

        ///////////////////////////////
      
        async consultAllMetrics(e){
          console.log(e)
          //https://esmtconnectororq.jakiodes.com/consultOrgMetrics.php?urlClient=
          axios.post(this.urlCONNECTOR+'consultOrgMetrics.php?urlClient='+e, {
                    headers:{

                    }
                    }).then(response => {
                        console.log(response)
                        let i=0
                        let arrayMetrics = response.data.data

                        console.log(arrayMetrics)

                        if(arrayMetrics!=null){

                          for(i=0; i<arrayMetrics.length; i++){

                            this.$set(this.orgMetricsTemp, i, arrayMetrics[i])
                            this.$set(this.orgMetricsTemp[i], 'tempID', i)
                            let e = 0

                            for(e=0; e<this.metricTemplates.length; e++){
                              let metricTemplateObj = Object.assign({}, this.metricTemplates[e])

                              /*
                              this.$set(this.orgMetricsTemp[0], 'data0', JSON.parse(JSON.stringify(data0New)))
              this.$set(this.orgMetricsTemp[0], 'data1', JSON.parse(JSON.stringify(data1New)))
                              */

                              if(metricTemplateObj.name == arrayMetrics[i].metric_name){
                                this.$set(this.orgMetricsTemp[i], 'data0', JSON.parse(JSON.stringify(metricTemplateObj.data0)))
                                this.$set(this.orgMetricsTemp[i], 'data1', JSON.parse(JSON.stringify(metricTemplateObj.data1)))
                                this.$set(this.orgMetricsTemp[i], 'data2', metricTemplateObj.data2)
                                this.$set(this.orgMetricsTemp[i], 'data3', JSON.parse(JSON.stringify(metricTemplateObj.data3)))
                                this.$set(this.orgMetricsTemp[i], 'data4', JSON.parse(JSON.stringify(metricTemplateObj.data4)))
                                this.$set(this.orgMetricsTemp[i], 'data5', JSON.parse(JSON.stringify(metricTemplateObj.data5)))
                                this.$set(this.orgMetricsTemp[i], 'result', arrayMetrics[i].consult)
                                this.$set(this.orgMetricsTemp[i], 'name', arrayMetrics[i].metric_name)
                                this.$set(this.orgMetricsTemp[i], 'description', arrayMetrics[i].metric_description)
                                this.$set(this.orgMetricsTemp[i], 'range', arrayMetrics[i].metric_historic_days)
                                this.$set(this.orgMetricsTemp[i], 'text', arrayMetrics[i].consult)
                                this.$set(this.orgMetricsTemp[i], 'type', arrayMetrics[i].metric_type)
                                this.$set(this.orgMetricsTemp[i], 'range', arrayMetrics[i].metric_historic_days)
                                this.$set(this.orgMetricsTemp[i], 'customRange', this.defaultCustomRange)
                              }

                              if(this.orgMetricsTemp[i].range == 'Personalizado'){
                                let metricaJSON = JSON.parse(this.orgMetricsTemp[i].text)
                                let intervalo = metricaJSON.interval.split("/")
                                this.orgMetricsTemp[i].customRange[0] = intervalo[0]
                                this.orgMetricsTemp[i].customRange[1] = intervalo[1]
                              }
                              
                              if(this.orgMetricsTemp[i].type == 'auto2'){
                                console.log('////////Revisando métrica de  tipo auto2')
                                
                                this.$set(this.orgMetricsTemp[i], 'filter', arrayMetrics[i].filters)
                                
                                let filterValuesString = arrayMetrics[i].filter_values

                                if(arrayMetrics[i].filters == 'userId' && filterValuesString!=''){
                                  let temp = filterValuesString.split(",");
                                  filterValuesString = temp
                                }

                                this.$set(this.orgMetricsTemp[i], 'filterValue', filterValuesString)
                                //this.$set(this.orgMetricsTemp[i], 'filterValues', filterValuesString)
                              }

                              if(this.orgMetricsTemp[i].type == 'auto3' || this.orgMetricsTemp[i].type == 'auto4'){
                                console.log('////////Revisando métrica de  tipo auto3/auto4')
                                
                                this.$set(this.orgMetricsTemp[i], 'metric_order', arrayMetrics[i].metric_order)
                                this.$set(this.orgMetricsTemp[i], 'metric_orderBy', arrayMetrics[i].metric_orderBy)
                                this.$set(this.orgMetricsTemp[i], 'paging', arrayMetrics[i].paging)
                              }
                              
                              if(arrayMetrics[i].metric_historic_days!='Hoy' && arrayMetrics[i].metric_historic_days!=null){
                                  //this.Metrics0DaysRangeSelect  = parseInt(arrayMetrics[i].metric_historic_days)
                                  //this.orgMetricsTemp[i]['range'] = parseInt(arrayMetrics[i].metric_historic_days)
                              }
                            }

                              let consultJSON = JSON.parse(arrayMetrics[i].consult)

                              console.log('Los  datos son:')
                              console.log(this.orgMetricsTemp[i])
                              console.log(consultJSON)

                              let a = 0

                              if(this.orgMetricsTemp[i].type != 'auto3' && this.orgMetricsTemp[i].type != 'auto4'){
                              
                                for(a=0; a<consultJSON.metrics.length; a++){
                                  let aa = 0
                                  for(aa=0; aa<this.orgMetricsTemp[i].data0.length; aa++){
                                    let nameKey = Object.keys(this.orgMetricsTemp[i].data0[aa])

                                    if(consultJSON.metrics[a] == nameKey){
                                      this.$set(this.orgMetricsTemp[i].data0[aa], nameKey, true)
                                    }
                                  }
                                }
                                
                                let u = 0

                                for(u=0; u<consultJSON.groupBy.length; u++){
                                  let uu = 0
                                  for(uu=0; uu<this.orgMetricsTemp[i].data1.length; uu++){
                                    let nameKey = Object.keys(this.orgMetricsTemp[i].data1[uu])

                                    if(consultJSON.groupBy[u] == nameKey){
                                      this.$set(this.orgMetricsTemp[i].data1[uu], nameKey, true)
                                    }
                                  }
                                }

                              }else{
                                for(a=0; a<this.orgMetricsTemp[i].data3.length; a++){
                                  let aa = 0
                                  for(aa=0; aa<this.orgMetricsTemp[i].data0.length; aa++){
                                    let nameKey = Object.keys(this.orgMetricsTemp[i].data0[aa])

                                    if(consultJSON.metrics[a] == nameKey){
                                      this.$set(this.orgMetricsTemp[i].data0[aa], nameKey, true)
                                    }
                                  }
                                }
                              }

                          }

                          this.orgMetricsTempNum  = this.orgMetricsTemp.length

                          this.urlExist = true
                          
                        }else{
                          this.urlExist = true
                          console.log('Este link no tiene métricas configuradas')
                        }
                        

                    }).catch(error => {
 
                        console.error(error)

                })
        },
        ////8tplm0sv759hrqyx
        async getAllTemplates(e){
          console.log(e)
          axios.post(this.urlCONNECTOR+'getAllMetricTemplates.php?urlClient='+e, {
                    headers:{

                    }
                    }).then(response => {
                        console.log(response)

                        this.metricTemplates = response.data.data

                        console.log(this.metricTemplates)

                        let i=0

                        for(i=0; i<this.metricTemplates.length; i++){
                          if(this.metricTemplates[i].type=='auto' || this.metricTemplates[i].type=='auto2'){
                            let data0set = JSON.parse(this.metricTemplates[i].data0)
                            let data1set = JSON.parse(this.metricTemplates[i].data1)

                            this.$set(this.metricTemplates[i], 'data0', data0set)
                            this.$set(this.metricTemplates[i], 'data1', data1set)

                          }

                          if(this.metricTemplates[i].type=='auto' || this.metricTemplates[i].type=='auto2' || this.metricTemplates[i].type=='auto3' || this.metricTemplates[i].type=='auto4'){

                            let data2set = this.metricTemplates[i].data2.substring(1, this.metricTemplates[i].data2.length - 1) 
                            data2set = data2set.replace(/'/g, "")
                            let data2setS = data2set.split(',')

                            this.$set(this.metricTemplates[i], 'data2', data2setS)
                          }

                          if(this.metricTemplates[i].type=='auto2'){
                            let data3set = this.metricTemplates[i].data3.substring(1, this.metricTemplates[i].data3.length - 1) 
                            data3set = data3set.replace(/'/g, "")
                            let data3setS = data3set.split(',')
                            this.$set(this.metricTemplates[i], 'data3', data3setS)
                          }

                          if(this.metricTemplates[i].type=='auto3'){
                            let data4set = this.metricTemplates[i].data4.substring(1, this.metricTemplates[i].data4.length - 1) 
                            data4set = data4set.replace(/'/g, "")
                            let data4setS = data4set.split(',')
                            this.$set(this.metricTemplates[i], 'data4', data4setS)

                            let data5set = this.metricTemplates[i].data5.substring(1, this.metricTemplates[i].data5.length - 1) 
                            data5set = data5set.replace(/'/g, "")
                            let data5setS = data5set.split(',')
                            this.$set(this.metricTemplates[i], 'data5', data5setS)
                          }

                          if(this.metricTemplates[i].type=='auto4'){
                            let data4set = this.metricTemplates[i].data4.substring(1, this.metricTemplates[i].data4.length - 1) 
                            data4set = data4set.replace(/'/g, "")
                            let data4setS = data4set.split(',')
                            this.$set(this.metricTemplates[i], 'data4', data4setS)
                          }
                          
                        }

                        console.log(this.metricTemplates)

                        this.consultAllMetrics(e)
                        

                    }).catch(error => {
 
                        console.error(error)

                })
        },

        async consultURLExist(e){
            console.log(e)
            let params = new FormData();

            params.append('urlClient', e)


                await axios.post(this.urlCONNECTOR+'consultURLorIDexist.php', params, {
                    headers:{

                        /*'Authorization': 'Bearer '+this.tokenGC, 
                        'Access-Control-Allow-Origin': '*',
                        'Accept': '*',
                        'Content-Type': 'application/json'*/
                    }
                    }).then(response => {
                        console.log(response)

                        if(response.data.error == null){

                            
                            this.dataUrlOrg = response.data.data

                            if(this.dataUrlOrg.url=='Data from ESMT connector'){
                              //this.urlExist = true
                              //this.consultAllMetrics()

                              this.getAllTemplates(e);
                              this.urlClient = e
                            }else{

                              this.errorConsult = true
                              this.errorConsultText = 'Este conector no está autorizado para usar las opciones de configuración.'
                            }
                            

                        }else{
                            this.errorConsult = true
                            this.errorConsultText = response.data.error
                            
                        }

                    }).catch(error => {
                        this.errorConsult = true
                        this.errorConsultText = error
                        console.error(error)

                  //this.auth = false

                })
        },

        async getAllConnectors(){

                await axios.post(this.urlCONNECTOR+'getAllConnectors.php?urlClient='+this.orgIdPropComp, {
                    headers:{

                        /*'Authorization': 'Bearer '+this.tokenGC, 
                        'Access-Control-Allow-Origin': '*',
                        'Accept': '*',
                        'Content-Type': 'application/json'*/
                    }
                    }).then(response => {
                        console.log(response)

                        if(response.data.code == '01'){

                            this.orgDBConnectors = response.data.data
                            

                        }else{
                            this.errorConsult = true
                            this.errorConsultText = response.data.error
                            
                        }

                    }).catch(error => {
                        this.errorConsult = true
                        this.errorConsultText = error
                        console.error(error)

                  //this.auth = false

                })
        }

       
    },
    watch:{

              
    },
        mounted(){
          if(!this.isAuthPropComp){
            this.$router.push('/')
          }

          /*var todayDate = new Date().toISOString().slice(0, 10);
          console.log(todayDate);*/

          let hoy = new Date().toISOString().slice(0, 10)
          //let ayer = new Date().setDate(new Date().getDate() - 1)

          let yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);

          this.defaultCustomRange = [yesterday.toISOString().slice(0, 10), hoy] 
          console.log(this.defaultCustomRange)

          this.getAllConnectors()

          let orgUsersSave = JSON.parse(localStorage.getItem('esmtConnectorApp'))

          let i=0
          for(i=0; i<orgUsersSave.client.entities.length; i++){
            this.$set(this.orgUsers, i, {'name': orgUsersSave.client.entities[i].name, 'id': orgUsersSave.client.entities[i].id})
          }  

        }
    
}
</script>
<style scoped>
.v-alert {
  position: fixed;
  left: 10%;
  top: 10%;
  margin: 0 auto; 
  z-index:999;
}
</style>