<template>
  <div class="Auth">
    <PowerbiConf  :tokenPropComp="tokenProp" :isAuthPropComp="isAuthProp" :orgIdPropComp="orgIdProp" msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PowerbiConf from '@/components/PowerBIConfig.vue'

export default {
  name: 'PowerBIConfig',
  props:{
    isAuthProp: {type: Boolean, default:false},
    tokenProp: {type: String, default:null},
    orgIdProp: {type: String, default:null}
  },
  components: {
    PowerbiConf
  }
}
</script>
