<template>
  <div class="Register">
    <RegisterComp msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import RegisterComp from '@/components/Register.vue'

export default {
  name: 'Register',

  components: {
    RegisterComp
  }
}
</script>
