import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.prototype.$urlCONNECTOR = 'https://esmtconnectororq.esmtcx.solutions/'
//Vue.prototype.$urlCONNECTOR = 'http://esmtauthorq.test/'

Vue.prototype.$urlAPP = 'https://esmtconnector.esmtcx.solutions/'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
