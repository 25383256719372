<template>
  <div class="Auth">
    <AuthComp @isAuthTrueEmit="attachIsAuth" msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import AuthComp from '@/components/Auth2.vue'

export default {
  name: 'Home',

  components: {
    AuthComp
  },
  methods:{
    attachIsAuth(e){
      this.$emit('isAuthTrueEmit', e)
    }
  }
}
</script>
