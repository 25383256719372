<template>
<v-container>
    <template class="text-center" v-if="!registerComplete">
        <v-alert v-if="errorRegister"
            border="left"
            color="red"
            text
            type="error"
            dismissible
            >
            {{errorMessage}}
            
          </v-alert>
          <v-container class="text-center" style="max-width:800px;" v-if="loadAllRegionsComplete">
            <p class="small" style="color:#333;"><small>Versión 1. Update 1.1</small></p>
            <v-card  elevation="2"
            outlined class="pl-4 pr-4 pt-4 pb-4">
            <v-form
              ref="form"
              lazy-validation
              
            >
            <v-card-title>
              <v-icon
                large
                left
              >
                mdi-bank
              </v-icon>
              <span class="text-h6 font-weight-light">Crear nueva organización</span>
            </v-card-title>

              <v-row>

              <!--<v-col md="12" sm="12">
                <v-text-field
                  v-model="register.name"
                  label="*Organization Name"
                  required
                ></v-text-field>
              </v-col>-->

              <v-col md="6" sm="12">
              <v-text-field
                v-model="register.gc_id"
                label="Genesys Cloud Organization ID"
                required
              ></v-text-field>
              </v-col>

              <v-col md="6" sm="12">
              <v-text-field
                v-model="register.gc_idUser"
                label="Genesys Cloud Admin UserID"
                required
              ></v-text-field>
              </v-col>

              <v-col md="6" sm="12">
              <v-text-field
                v-model="register.gc_clientID"
                label="Default oAuth Client ID"
                required
              ></v-text-field>
              </v-col>

              <v-col md="6" sm="12">
              <v-text-field
                v-model="register.gc_secretClient"
                label="Default oAuth Secret Client"
                required
              ></v-text-field>
              </v-col>

              <v-col md="6" sm="12">
              <v-select
                  :items="regions"
                  @change="addItemsRegion"
                  name="id"
                  item-value="this"
                  value="aws"
                  class="mb-0 pb-0"
                  dense
                  solo
                  label="Región de la Org"
                  required
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.aws}}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.aws }}
                  </template>
                </v-select>
              </v-col>

              <v-col md="6" sm="12">

              </v-col>

              <v-col md="12">
                <v-textarea
                  background-color="grey lighten-2"
                  color="cyan"
                  label="Instrucciones"
                  style="font-size:12px;"
                  class="p-4"
                  auto-grow
                  readonly
                  value="*Ingresa el ID de tu organización en Genesys
*Ingresa el ID de un usuario con rol administrador (No se validará el registro si es un usuario con un rol menor).
*Crea un oAuth de tipo Cliente(Client) agregando todos los permisos.
*Agrega el Client ID obtenido al crear el oAuth.
*Agrega el Secret Client obtenido al crear el oAuth.
*IMPORTANTE: Si alguno de los valores inrgesados está errados, los conectores fallarán y tendrá que solicitar un nuevo registro de organización.
*IMPORTANTE: Genesys Cloud Organization ID, Default oAuth Client ID y Default oAuth Secret Client serán tus datos de acceso a tus conectores"
                >
                
                </v-textarea>

              </v-col>
              <v-col md="12">
                <p class="text-center">Certifico que todos los datos ingresados son correctos. Certifico que estoy autorizado a utilizar los datos ingresados para poder registrar a la organización dentro del conector y poder utilizar las funcionalidades disponibles.</p>

              </v-col>

              <!--<v-col md="12" sm="12" class="mt-0 pt-0">
              <v-text-field
                v-model="register.description"
                label="*Connector Description"
                required
              ></v-text-field>
              </v-col>

              

              <v-col md="4" sm="12">
              <v-select
                v-model="register.product_connector"
                :items="products"
                @change="clearForm0"
                name="product_connector"
                item-text="product_connector"
                filled
                label="*Connection Receiver"
              ></v-select>
              </v-col>

              <v-col md="4" sm="12">
              <v-select
                v-if="register.product_connector != 'Internal Development'"
                v-model="register.product_connector_powerBItype"
                
                :items="powerBIconnection"
                name="product_connector"
                item-text="product_connector"
                filled
                label="*Connection Receiver"
              ></v-select>
              </v-col>

              <v-col md="4" sm="12">
              <v-text-field v-if="register.product_connector != 'Internal Development' && register.product_connector_powerBItype == 'Data from redirection'"
                v-model="register.url_client_service"
                label="*Development Type"
                required
              ></v-text-field>
              </v-col>

              <v-hr v-if="register.product_connector != 'Internal Development' && register.product_connector_powerBItype == 'Data from ESMT connector'"/>

              <v-col md="12" sm="12" class="mt-0 pt-0" v-if="register.product_connector != 'Internal Development' && register.product_connector_powerBItype == 'Data from ESMT connector'">
              <span class="text-h6 font-weight-light">Las métricas serán configuradas más adelante</span>
              </v-col>-->

              

              <!--<v-text-field
                v-model="tokenGC"
                label="Token Auth (Bearer)"
                required
              ></v-text-field>

              <v-btn v-if="tokenGC==''" :disabled="(clientId=='' || secretClient=='')"
                color="blue"
                class="mr-4"
                @click="authToken"
              >
                Crear Token Auth
              </v-btn>-->

              </v-row>
              
              <v-btn  :disabled="register.gc_id=='' || register.gc_idUser=='' || register.gc_clientID=='' || register.gc_secretClient=='' || register.api=='' || register.login==''"
                color="success"
                class="mr-4"
                @click="createNewOrganization()"
              >
                Registrar nueva organización
              </v-btn>
              
            </v-form>
            </v-card>
          </v-container>
          <v-container v-else>
            <p>Cargando información, por favor espere...</p>
          </v-container>
    </template>
    <template class="text-center" v-else>
          <v-container class="text-center" style="max-width:600px;">
            <v-card  elevation="2"
            outlined class="pl-4 pr-4 pt-4 pb-4 bgColorESMT">

            <v-card-text>
              <h1 style="color:#FFF" class="pb-4">¡Enhorabuena!</h1>
              <p style="color:#FFF">Haz registrado tu organización correctamente.</p>
              <h2 style="color:#FFF;"><strong>ID de la organización:</strong> {{registerCompleteID}}</h2>
              <p style="color:#FFF"><strong>Nombre de la Organización:</strong> {{register.name}}</p>
              
            </v-card-text>
            <v-divider class="mx-4" style="background-color:#FFF;"></v-divider>
            <v-card-actions style="margin:auto; text-align:center;">
              <v-btn 
              @click="goToHome()"
              >
                Ir al Inicio
              </v-btn>
              <v-btn 
                @click="goToAuth()"
                color="deep-orange"
              >
                Generar Conector
              </v-btn>
            </v-card-actions>
            </v-card>
          </v-container>
    </template>
  </v-container>
  <!--<v-container v-else>
      <h2 class="text-center">App Autenticada</h2>
      <p>Ya puedes usar la App</p>
  </v-container>-->
</template>

<script>
import axios from 'axios'
export default {
    
   name:'Register',
    data() {
        return {
            clientId: '',
            //secretClient: '',
            clientSecret:'',
            tokenGC: '',
            token: '',
            jsonOauthToken: {},
            register:{
              product_connector_powerBItype:'',
              gc_id:'',
              gc_idUser:'',
              gc_clientID:'',
              gc_secretClient:'',
              name:'',
              description:'',
              product_connector:'PowerBI',
              url_client_service:'',
              api:'',
              login:''
            },
            regions:null,
            loadAllRegionsComplete:false,
            errorRegister: false,
            errorMessage:'',
            registerComplete: false,
            registerCompleteID:'',
            products:['PowerBI','Internal Development'],
            powerBIconnection:['Data from redirection', 'Data from ESMT connector'],
            urlCONNECTOR: this.$urlCONNECTOR,
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
    methods:{
      addItemsRegion(e){
        //let region = e
        this.register.api = e.api
        this.register.login = e.login
      },
      clearForm0(){
        this.register.product_connector_powerBItype=''
        this.register.url_client_service=''
      },
      goToHome(){
            this.$router.push({ name: 'Welcome' })
          },

          goToAuth(){
            this.$router.push({ name: 'Auth' })
          },

        async getAllRegions(){
          await axios.get(this.urlCONNECTOR+'getAllRegions.php', {
              headers:{
                /*'Authorization': 'Bearer '+this.tokenGC, 
                'Access-Control-Allow-Origin': '*',
                'Accept': '*',
                'Content-Type': 'application/json'*/
                
              }
            }).then(response => {
                  console.log(response)
                  if(response.data.code == '01'){
                    this.loadAllRegionsComplete = true
                    this.regions = response.data.data
                  }else{
                    this.errorMessage = response.data.data.error
                  }

                }).catch(error => {
                  this.errorRegister = true
                  this.errorMessage = error
                  console.error(error)


                })
        },

        async createNewOrganization(){
          const params = new URLSearchParams();
          //params.append('k', 'val');
          params.append('gc_id', this.register.gc_id)
          params.append('gc_idUser', this.register.gc_idUser)
          params.append('gc_client_id', this.register.gc_clientID)
          params.append('gc_secret_client', this.register.gc_secretClient)
          params.append('api', this.register.api)
          params.append('login', this.register.login)
          /*params.append('description', this.register.description)
          params.append('product_connector', this.register.product_connector)
          if(this.register.product_connector_powerBItype == 'Data from redirection'){
            params.append('url_client_service', this.register.url_client_service)
          }else{
            params.append('url_client_service', this.register.product_connector_powerBItype)
          }*/
          
           await axios.get(this.urlCONNECTOR+'esmtdatabaseregister.php', {params: params}, {
              headers:{
                /*'Authorization': 'Bearer '+this.tokenGC, 
                'Access-Control-Allow-Origin': '*',
                'Accept': '*',
                'Content-Type': 'application/json'*/
                
              }
            }).then(response => {
                  console.log(response)
                  if(response.data.code == '01'){
                    this.registerComplete = true
                    this.registerCompleteID = response.data.data.id    
                  }else{
                    this.errorRegister = true
                    this.errorMessage = response.data.data.error
                  }

                }).catch(error => {
                  this.errorRegister = true
                  this.errorMessage = error
                  console.error(error)


                })
        }
    },
    watch:{
      
              
    },
        mounted(){
          this.getAllRegions()
            

        }
    
}
</script>
<style scoped>

</style>