<template>
<v-container>
    <template class="text-center" v-if="!tokenGenerate">
        <v-alert v-if="errorConsult"
            border="left"
            color="red"
            text
            type="error"
            dismissible
            >
            {{errorConsultText}}
            
          </v-alert>
          <v-container class="text-center" style="max-width:800px;">

            <v-card  elevation="2"
            outlined class="pl-4 pr-4 pt-4 pb-4">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              
            >

            <v-card-title>
              <v-icon
                large
                left
              >
                mdi-bank
              </v-icon>
              <span class="text-h6 font-weight-light">Crear nuevo conector</span>
            </v-card-title>

              <v-row>

              <!--<v-text-field
                v-model="organization_id"
                label="*Conn ID"
                style="color:#FFF;"
                required
              ></v-text-field>-->
              <v-col md="6" sm="12" class="pt-2">
              <v-text-field
                v-model="clientId"
                label="*Client ID (Genesys Cloud)"
                required
              ></v-text-field>
              </v-col>

              <v-col md="6" sm="12" class="pt-2">
              <v-text-field
                v-model="clientSecret"
                label="*Secret Client (Genesys Cloud)"
                required
              ></v-text-field>
              </v-col>

              <!--<v-col md="12" sm="12" class="pt-2">
              <v-text-field
                v-model="tokenGC"
                label="*Token Authorization"
                required
                disabled
              ></v-text-field>
              </v-col>-->

              <v-col md="6" sm="12" class="pt-2">
              <v-text-field
                v-model="register.gc_id"
                label="Genesys Organization ID"
                required
                readonly
              ></v-text-field>
              </v-col>

              <v-col md="6" sm="12" class="pt-2">
              <v-text-field
                v-model="register.description"
                label="*Connector Description"
                required
              ></v-text-field>
              </v-col>

              <v-col md="4" sm="12">
              <v-select
                v-model="register.product_connector"
                :items="products"
                @change="clearForm0"
                name="product_connector"
                item-text="product_connector"
                filled
                label="*Type"
              ></v-select>
              </v-col>

              <v-col md="4" sm="12">
              <v-select
                v-if="register.product_connector != 'For Token Autogenerate'"
                v-model="register.product_connector_powerBItype"
                
                :items="powerBIconnection"
                name="product_connector"
                item-text="product_connector"
                filled
                label="*Connection Receiver"
              ></v-select>
              </v-col>

              <v-col md="4" sm="12">
              <v-text-field v-if="register.product_connector != 'For Token Autogenerate' && register.product_connector_powerBItype == 'Data from redirection'"
                v-model="register.url_client_service"
                label="*Development Type"
                required
              ></v-text-field>
              </v-col>

              <!--<v-hr v-if="register.product_connector != 'For Token Autogenerate' && register.product_connector_powerBItype == 'Data from ESMT connector'"/>-->

              <v-col md="12" sm="12" class="mt-0 pt-0" v-if="register.product_connector != 'For Token Autogenerate' && register.product_connector_powerBItype == 'Data from ESMT connector'">
              <span class="text-h6 font-weight-light">Las métricas serán configuradas más adelante</span>
              </v-col>

              <!--<v-text-field
                v-model="tokenGC"
                label="Token Auth (Bearer)"
                required
              ></v-text-field>

              <v-btn v-if="tokenGC==''" :disabled="(clientId=='' || secretClient=='')"
                color="blue"
                class="mr-4"
                @click="authToken"
              >
                Crear Token Auth
              </v-btn>-->

              </v-row>
              
              <v-btn @click="createNewConnector" color="success" :disabled="(clientId=='' || clientSecret=='' || register.description=='' || register.product_connector=='') || (register.product_connector=='PowerBI' && register.product_connector_powerBItype=='') || (register.product_connector=='PowerBI' && register.product_connector_powerBItype=='Data from redirection' && register.url_client_service=='')">
                Generar URL
              </v-btn>
              
            </v-form>
            </v-card>
            </v-container>
          </template>
          <template class="text-center" v-else>
          <v-container class="text-center" style="max-width:800px;">
            <v-card  elevation="2"
            outlined class="pl-4 pr-4 pt-4 pb-4 bgColorESMT">

            <v-card-text>
              <h1 style="color:#FFF" class="pb-4">¡Enhorabuena!</h1>
              <p style="color:#FFF">Haz generado tu URL Connector correctamente</p>
              <h2 style="color:#FFF;">{{urlAPP}}c/{{organization_id}}</h2>
              <p style="color:#FFF">La url servirá para poder consultar cualquier API de Genesys Cloud</p>
              
            </v-card-text>
            <v-divider class="mx-4" style="background-color:#FFF;"></v-divider>
            <div class="pt-4" style="margin:auto; text-align:center; width:100%;">
              <v-btn style="display:inline-block;"
              @click="goToHome()"
              >
                Ir al Inicio
              </v-btn>
              <v-btn style="display:inline-block;"
              @click="goToConnectors()"
              >
                Mis conectores
              </v-btn>
            </div>
            </v-card>
          </v-container>
    </template>
  </v-container>
  <!--<v-container v-else>
      <h2 class="text-center">App Autenticada</h2>
      <p>Ya puedes usar la App</p>
  </v-container>-->
</template>

<script>
import axios from 'axios'
export default {
    props:{
      tokenPropComp: {type:String, default:null},
      isAuthPropComp: {type: Boolean, default:false},
      orgIdPropComp: {type:String, default:null},
      clientPropComp: {type:Object, default:null},
      clientIDPropComp: {type:String, default:null},
      clientSecretPropComp: {type:String, default:null}
    },
   name:'NewConnectorComp',
    data() {
        return {
            client: this.clientPropComp,
            clientId: this.clientIDPropComp,
            organization_id: '',
            //secretClient: '',
            clientSecret: this.clientSecretPropComp,
            tokenGC: this.tokenPropComp,
            token: '',
            jsonOauthToken: {},
            message:{
                from: "",
                pre: "",
                to: "",
                body: ""
            },
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            valid:false,
            typeSelected:'',
            theApp:{
                name:'',
                description: '',
                state: ''
            },
            register:{
              product_connector_powerBItype:'',
              gc_id: this.orgIdPropComp,
              gc_idUser:'',
              gc_clientID:'',
              gc_secretClient:'',
              name:'',
              description:'',
              product_connector:'PowerBI',
              url_client_service:''
            },
            errorConsult: false,
            errorConsultText: '',
            tokenGenerate:false,
            initGenerateToken:false,
            autoTokenGenerate: false,
            products:['PowerBI','For Token Autogenerate'],
            powerBIconnection:['Data from redirection', 'Data from ESMT connector'],
            powerBIconnectionSelected:'',
            urlCONNECTOR: this.$urlCONNECTOR,
            urlAPP: this.$urlAPP,
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
    methods:{
      clearForm0(){
        this.register.product_connector_powerBItype=''
        this.register.url_client_service=''
      },
      goToHome(){
            this.$router.push({ name: 'Welcome' })
          },

      goToConnectors(){
            this.$router.push({ name: 'PowerBIConfig' })
          },
        async createNewConnector(){

            //Base24ClientCredential
            
            let Base24 = Buffer.from(this.clientId+ ':' + this.clientSecret).toString('base64')

            const params = new URLSearchParams();

            params.append('org_id', this.orgIdPropComp)
            params.append('client_id', this.clientId)
            params.append('secret_client', this.clientSecret)
            params.append('Base24ClientCredential', Base24)
            params.append('type', 'client_credentials')
            params.append('description', this.register.description)  
            params.append('gc_id', this.register.gc_id)
            params.append('product_connector', this.register.product_connector)
            params.append('product_connector_powerBItype', this.register.product_connector_powerBItype)
            params.append('url_client_service', this.register.url_client_service)

            //console.log(Base24)
            this.initGenerateToken = true

            this.errorConsult = false

                await axios.post(this.urlCONNECTOR+'esmtdatabaseregisterconnector.php', params, {
                    headers:{

                        /*'Authorization': 'Bearer '+this.tokenGC, 
                        'Access-Control-Allow-Origin': '*',
                        'Accept': '*',
                        'Content-Type': 'application/json'*/
                    }
                    }).then(response => {
                        console.log(response)
                        console.log(response.data.code)

                        //this.initGenerateToken = false

                        /*if(response.data.error == null){
                            
                            console.log('Token '+response.data.access_token+' generado exitosamente')
                            this.tokenGC = response.data.access_token

                            this.errorConsult = false
                            this.errorConsultText = ''
                            
                            this.authApp()

                            
                        }else{*/
                            /*console.log(response.data.error)
                            this.errorConsult = true
                            this.errorConsultText = response.data.error_description
                            console.log(response.data.error_description)
                        }*/

                        if(response.data.code == '01'){

                          /*console.log('Token '+response.data.access_token+' generado exitosamente')
                          this.tokenGC = response.data.access_token  
                          this.tokenGenerate = true*/
                          
                          this.tokenGenerate = true
                          this.organization_id = response.data.data.connector_id



                        }else{
                          this.errorConsult = true
                          this.errorConsultText = response.data.data
                        }

                    }).catch(error => {
                        /*this.initGenerateToken = false
                        this.errorConsult = true
                        this.errorConsultText = error*/
                        console.error(error)

                  //this.auth = false

                })
        },

       
    },
    watch:{
      orgIdPropComp: function(n){
        if(n!=null){
          this.register.gc_id = this.orgIdPropComp
        }
      }
              
    },
        mounted(){

          

          if(!this.isAuthPropComp){
            this.$router.push('/')
          }
          
          
            

        }
    
}
</script>
<style scoped>

</style>