<template>
  <div class="Auth">
    <NewConnectorComp @isAuthTrueEmit="attachIsAuth" :clientIDPropComp="clientIDProp" :clientSecretPropComp="clientSecretProp" :clientPropComp="clientProp" :tokenPropComp="tokenProp" :isAuthPropComp="isAuthProp" :orgIdPropComp="orgIdProp" msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import NewConnectorComp from '@/components/NewConnector.vue'

export default {
  name: 'NewConnector',
  props:{
    isAuthProp: {type: Boolean, default:false},
    tokenProp: {type: String, default:null},
    orgIdProp: {type: String, default:null},
    clientProp: {type:Object, default:null},
    clientIDProp: {type:String, default:null},
    clientSecretProp: {type:String, default:null}
  },

  components: {
    NewConnectorComp
  },

  methods:{
    attachIsAuth(e){
      this.$emit('isAuthTrueEmit', e)
    }
  }
}
</script>
