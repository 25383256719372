<template>
<v-container>
    <template class="text-center">
        <v-alert v-if="errorConsult"
            border="left"
            color="red"
            text
            type="error"
            dismissible
            >
            {{errorConsultText}}
            
          </v-alert>
          <v-container class="text-center" style="max-width:500px;">
            <p class="small" style="color:#333;"><small>Versión 1. Update 1.1</small></p>
            <v-card  elevation="2"
            outlined class="pl-4 pr-4 pt-4 pb-4">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              
            >
            <v-card-title>
                <v-icon
                  large
                  left
                >
                  mdi-bank
                </v-icon>
                <span class="text-h6 font-weight-light">Auth Process</span>
            </v-card-title>
            <v-select
                v-model="authTypeSelected"
                :items="authType"
                name="authType"
                item-text="authType"
                filled
                label="Select type Auth Process"
              ></v-select>

            
            <div v-if="authTypeSelected=='ClientID/SecretClient Auth'">
              <v-text-field
                v-model="orgId"
                label="Genesys Organization ID"
                required
              ></v-text-field>
              
              <v-text-field
                v-model="clientId"
                label="Default Client ID"
                required
              ></v-text-field>

              <v-text-field
                v-model="clientSecret"
                label="Default Secret Client"
                required
              ></v-text-field>
            </div>
            <div v-else>
              <v-text-field
                v-model="connectorURL"
                label="Paste ESMT Conn URL Connector"
                required
              ></v-text-field>
            </div>

              <!--<v-text-field
                v-model="tokenGC"
                label="Token Auth (Bearer)"
                required
              ></v-text-field>

              <v-btn v-if="tokenGC==''" :disabled="(clientId=='' || secretClient=='')"
                color="blue"
                class="mr-4"
                @click="authToken"
              >
                Crear Token Auth
              </v-btn>-->
              
              <v-btn  :disabled="(authTypeSelected=='ClientID/SecretClient Auth' && (clientId=='' || clientSecret=='')) || (authTypeSelected=='ESMT Conn URL Connector' && connectorURL=='')"
                color="success"
                class="mr-4"
                @click="getIpAddress"
              >
                Acceder
              </v-btn>
              
            </v-form>
            </v-card>
            </v-container>
    </template>
  </v-container>
  <!--<v-container v-else>
      <h2 class="text-center">App Autenticada</h2>
      <p>Ya puedes usar la App</p>
  </v-container>-->
</template>

<script>
import axios from 'axios'
export default {
    
   name:'AuthApp2',
    data() {
        return {
            orgId: '',
            clientId: '',
            //secretClient: '',
            clientSecret:'',
            connectorURL:'',
            tokenGC: '',
            token: '',
            jsonOauthToken: {},
            message:{
                from: "",
                pre: "",
                to: "",
                body: ""
            },
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            valid:false,
            typeSelected:'',
            theApp:{
                name:'',
                description: '',
                state: ''
            },
            authType:['ClientID/SecretClient Auth', 'ESMT Conn URL Connector'],
            authTypeSelected:'ClientID/SecretClient Auth',
            errorConsult: false,
            errorConsultText: '',
            autoTokenGenerate: false,
            orgDBID:'',
            ip:''
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
    methods:{
      async getIpAddress() {
        this.errorConsult = false
          await axios.get(this.$urlCONNECTOR+'getIp.php', {
              headers:{
                /*'Authorization': 'Bearer '+this.tokenGC, 
                'Access-Control-Allow-Origin': '*',
                'Accept': '*',*/
                'Content-Type': 'application/json',
                'Accept': '*',
                'Access-Control-Allow-Origin': '*'
                
              }
            }).then(response => {
              console.log(response)
              this.ip = response.data.data
              this.verifyDuplicateSession()
            }).catch(error => {
                  this.errorConsult = true
                  this.errorConsultText = error
                  console.error(error)


            })
      },

      async verifyDuplicateSession(){
        
        this.errorConsult = false
        const params = new URLSearchParams();

        params.append('ip', this.ip)
        params.append('gc_id', this.orgId)
        params.append('gc_client_id', this.clientId)
        params.append('gc_secret_client', this.clientSecret)

        await axios.get(this.$urlCONNECTOR+'verifyDuplicateSession.php', {params: params}, {
              headers:{
                /*'Authorization': 'Bearer '+this.tokenGC, 
                'Access-Control-Allow-Origin': '*',
                'Accept': '*',
                'Content-Type': 'application/json'*/
                
              }
            }).then(response => {
                  console.log(response)
                  if(response.data.code == '01' && response.data.data.session == 'Ok'){
                    this.obtainAuthToken()
                  }else if(response.data.code == '01' && response.data.data.session != 'Ok' && response.data.data.session != null){
                    this.errorConsult = true
                    this.errorConsultText = response.data.data.session
                  
                  }else if(response.data.code == '02'){
                    this.errorConsult = true
                    this.errorConsultText = response.data.data
                  
                  }else{
                    this.errorConsult = true
                    this.errorConsultText = response.data
                  }

                }).catch(error => {
                  this.errorConsult = true
                  this.errorConsultText = error
                  console.error(error)


                })
      },

        async obtainAuthToken(){
            this.errorConsult = false
            //Base24ClientCredential
            
            let Base24 = Buffer.from(this.clientId+ ':' + this.clientSecret).toString('base64')

            let url = ''

            if(this.authTypeSelected=='ClientID/SecretClient Auth'){
              url = 'https://esmtgenesysauth.esmtcx.solutions/genesysobtaintoken2.php?type=client_credentials&Base24ClientCredential='+Base24+'&org_id='+this.orgId
            }else{
              url = this.connectorURL
            }

            this.errorConsult = false
                //
                await axios.get(url, {
                //await axios.get(, {
                    headers:{

                        /*'Authorization': 'Bearer '+this.tokenGC, 
                        'Access-Control-Allow-Origin': '*',
                        'Accept': '*',
                        'Content-Type': 'application/json'*/
                    }
                    }).then(response => {
                        console.log(response)

                       if(response.data.code == '01'){
                            
                            console.log('Token '+response.data.access_token+' generado exitosamente')
                            this.tokenGC = response.data.client.access_token

                            if(this.authTypeSelected=='ESMT Conn URL Connector'){
                              this.clientId=response.data.client.client_id
                            }

                            this.errorConsult = false
                            this.errorConsultText = ''

                            this.orgDBID=response.data.orgId
                            
                            this.authApp()
                        }else{
                            console.log(response.data)
                            this.errorConsult = true
                            this.errorConsultText = response.data.data.error
                            console.log(response.data.data.error)
                        }

                    }).catch(error => {
                        this.errorConsult = true
                        this.errorConsultText = error
                        console.error(error)

                  //this.auth = false

                })
        },

        async authApp(){
          this.errorConsult = false
          ///
          //await axios.get('https://esmtgenesysauth.esmtcx.solutions/genesysauth.php?theid='+this.clientId+'&theT='+this.tokenGC, {
           await axios.get('https://esmtgenesysauth.esmtcx.solutions/genesysauth2.php?theid='+this.clientId+'&theT='+this.tokenGC, {
              headers:{
                /*'Authorization': 'Bearer '+this.tokenGC, 
                'Access-Control-Allow-Origin': '*',
                'Accept': '*',
                'Content-Type': 'application/json'*/
              }
            }).then(response => {
                  console.log('//Org info')
                  console.log(response)

                  let genesysClientInfo = {}

                  genesysClientInfo.client = response.data.data2
                  genesysClientInfo.t = this.tokenGC
                  genesysClientInfo.orgDBID = this.orgDBID
                  genesysClientInfo.ClientID = this.clientId
                  genesysClientInfo.ClientSecret = this.clientSecret
                  genesysClientInfo.Ip = this.ip
                  //genesysClientInfo.m = response.data.data3

                  localStorage.setItem('esmtConnectorApp', JSON.stringify(genesysClientInfo))

                  /*this.theApp.name = response.data.name
                  this.theApp.description = response.data.description
                  this.theApp.state = response.data.state

                  this.auth = true*/
                  this.createNewSession()

                  this.$emit('isAuthTrueEmit', [genesysClientInfo, this.orgDBID])
                  //this.$emit('clientEmit', genesysClientInfo)
                  this.$router.push({ name: 'Welcome' })

                }).catch(error => {
                    console.error(error)

                  this.auth = false

                })
        },

        async createNewSession(){
          const params = new URLSearchParams();
          //params.append('k', 'val');
          params.append('ip', this.ip)
          params.append('orgDBID', this.orgDBID)
          
           await axios.get(this.$urlCONNECTOR+'createSession.php', {params: params}, {
              headers:{
                /*'Authorization': 'Bearer '+this.tokenGC, 
                'Access-Control-Allow-Origin': '*',
                'Accept': '*',
                'Content-Type': 'application/json'*/
                
              }
            }).then(response => {
              console.error(response)
            }).catch(error => {
              console.error(error)

           })
        }
    },
    watch:{
      autoTokenGenerate: function(newValue) {
              //zm8lO9Y9QKGKTeS-BoHCKA
                //console.log('detectado cambio en '+newValue)
                if(newValue!=null){
                  if(newValue == true){
                    this.obtainAuthToken()
                  }
                     
                }

      },
              
    },
        mounted(){

            let theAppLocal = null
            this.auth = false
        
            if(localStorage.getItem('esmtConnectorApp')==null){
                this.auth = false
            }else{
              theAppLocal = JSON.parse(localStorage.getItem('esmtConnectorApp'))

              if(theAppLocal!=null && theAppLocal!=undefined){
                this.$router.push('Welcome')
              }

              /*this.theApp.name = theAppLocal.name
              this.theApp.description = theAppLocal.description
              this.theApp.state = theAppLocal.state

              this.clientId = theAppLocal.client.id
              this.clientSecret = theAppLocal.client.secret*/

              /*if(theAppLocal.t!=null){
                
              }*/
                

                this.autoTokenGenerate = true
/*
                //this.auth = true

                //this.$router.push({ name: 'App' })*/
            }

            console.log(theAppLocal)

        }
    
}
</script>
<style scoped>

</style>